import { DeviceNotificationEvent, LandfillNotificationEvent, NotificationEventDetail } from "@apis/queue";

export const NOTIFICATION_EVENT = {
  "CH4_DELTA_STOPPED": "CH4_DELTA_STOPPED",
  "VALVE_ERROR": "VALVE_ERROR",
  "PHONE_VERIFICATION": "PHONE_VERIFICATION",
  "SET_PASSWORD_LINK": "SET_PASSWORD_LINK",
  "BATTERY_ALERT": "BATTERY_ALERT",
  "CALIBRATION_ERROR": "CALIBRATION_ERROR",
  "DEVICE_ERROR": "DEVICE_ERROR",
  "LOW_INFLOW": "LOW_INFLOW",
  "OFFLINE_DEVICES": "OFFLINE_DEVICES",
  "LF_DAILY_REPORTS": "LF_DAILY_REPORTS",
  "LF_ALERT_PARAMETERS": "LF_ALERT_PARAMETERS",
} as const

export const NOTIFICATION_EVENTS = [
  NOTIFICATION_EVENT.CH4_DELTA_STOPPED,
  NOTIFICATION_EVENT.VALVE_ERROR,
  NOTIFICATION_EVENT.PHONE_VERIFICATION,
  NOTIFICATION_EVENT.SET_PASSWORD_LINK,
  NOTIFICATION_EVENT.BATTERY_ALERT,
  NOTIFICATION_EVENT.CALIBRATION_ERROR,
  NOTIFICATION_EVENT.DEVICE_ERROR,
  NOTIFICATION_EVENT.LOW_INFLOW,
  NOTIFICATION_EVENT.OFFLINE_DEVICES,
  NOTIFICATION_EVENT.LF_DAILY_REPORTS,
  NOTIFICATION_EVENT.LF_ALERT_PARAMETERS
] as const

export const DEVICE_NOTIFICATION_EVENTS = [
  NOTIFICATION_EVENT.VALVE_ERROR,
  NOTIFICATION_EVENT.CH4_DELTA_STOPPED,
  NOTIFICATION_EVENT.BATTERY_ALERT,
] as const

export const LANDFILL_NOTIFICATION_EVENTS = [
  NOTIFICATION_EVENT.LF_DAILY_REPORTS,
  NOTIFICATION_EVENT.LF_ALERT_PARAMETERS
] as const

export const NOTIFICATION_EVENT_DETAILS: Record<DeviceNotificationEvent & LandfillNotificationEvent, NotificationEventDetail> =  {
  [NOTIFICATION_EVENT.CH4_DELTA_STOPPED]: {
    type: "device",
    label: "CH4 Delta Stop",
    description: "If the control algorithm disabled by CH4 delta stop"
  },
  [NOTIFICATION_EVENT.VALVE_ERROR]: {
    type: "device",
    label: "Valve Error",
    description: "If the valve adjustment fails"
  },
  [NOTIFICATION_EVENT.BATTERY_ALERT]: {
    type: "device",
    label: "Battery Alert",
    description: "If the SoC level drops below 20%"
  },
  [NOTIFICATION_EVENT.LF_DAILY_REPORTS]: {
    type: "landfill",
    label: "Daily Reports",
    description: "Receive daily landfill reports",
    disabledChannels: ["sms"]
  },
  [NOTIFICATION_EVENT.LF_ALERT_PARAMETERS]: {
    type: "landfill",
    label: "Alert Parameters",
    description: "Receive alerted parameters for each devices",
    disabledChannels: ["email"]
  }
}

export const NOTIFICATION_CHANNEL = {
  "EMAIL": "email",
  "SMS": "sms",
  "SLACK": "slack"
} as const

export const NOTIFICATION_CHANNELS = [
  NOTIFICATION_CHANNEL.EMAIL,
  NOTIFICATION_CHANNEL.SMS,
  NOTIFICATION_CHANNEL.SLACK
] as const

export const USER_NOTIFICATION_CHANNELS = [
  NOTIFICATION_CHANNEL.EMAIL,
  NOTIFICATION_CHANNEL.SMS,
]
