import { StrictMode, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./locales";
import App from "./app/app";
import { RecoilRoot } from "recoil";
import { environment } from "./environments/environment";
import RecoilNexus from "recoil-nexus";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./query";

import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

environment.SENTRY_DSN && Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
      tracePropagationTargets: [/^https:\/\/(test\.)?cloud\.apisinnovation.com/],
    }),
    new Sentry.Replay()
  ],
  release: `apis-frontend@${environment.APP_VERSION}`,
  environment: environment.APP_ENVIRONMENT,
  tracesSampleRate: 0.2,
  tracePropagationTargets: [/^https:\/\/(test\.)?cloud\.apisinnovation.com/],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
})


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <Auth0Provider
      domain={environment.AUTH0_DOMAIN}
      clientId={environment.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: environment.AUTH0_AUDIENCE,
        scope: "openid profile email",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <RecoilNexus />
          <App />
        </RecoilRoot>
      </QueryClientProvider>
    </Auth0Provider>
  </StrictMode>
);
