import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accessToken } from "../store";
import { UserScope } from "@apis/types";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo } from "react";
import { LoadingOverlay } from "@mantine/core";
import { environment } from "../environments/environment";
import { useGetRolesSelf } from "../query";
import { canUserAccessResource } from "@apis/utils";
import { showNotification } from "@mantine/notifications";
import { DateTime } from "luxon";

interface ProtectedRouteProps {
  children: JSX.Element,
  resources?: { companyKey?: string, landfillKey?: string }
  scope?: UserScope
  validation?: { params?: Record<string, (arg: string) => boolean>, fallbackURL?: string }
}

const ProtectedRoute = ({ scope, resources, validation, children }: ProtectedRouteProps) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(accessToken);
  const { data: userRoles, isLoading: isLoadingRoles } = useGetRolesSelf()
  const params = useParams()
  useEffect(() => {
    if (validation?.params) {
      const isValid = Object.entries(validation.params).every(([parameter, validate]) => {
        const value = params[parameter];
        return value && validate(value);
      })

      if (!isValid) {
        if (validation.fallbackURL) {
          navigate(validation.fallbackURL)
        } else {
          navigate("/")
        }
      }
    }
  }, [params, validation]);

  const companyID = useMemo(() => resources && resources.companyKey && params[resources.companyKey] , [resources])
  const landfillID = useMemo(() => resources && resources.landfillKey && params[resources.landfillKey] , [resources])

  useEffect(() => {
    if(!isLoading && !isLoadingRoles) {
      if(!isAuthenticated) {
        navigate("/login");
      } else {
        if (userRoles) {
          const routeResources = (landfillID || companyID) && { landfillID, companyID }
          const canAccess = canUserAccessResource(userRoles, scope, routeResources)
          if (scope && !canAccess) {
            navigate("/");
          }
        }
      }
    }
  }, [isAuthenticated, isLoading, navigate, scope, isLoadingRoles, userRoles, landfillID, companyID]);

  useEffect(() => {
    const getToken = async () => {
      const newToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: environment.AUTH0_AUDIENCE,
          scope: "openid profile email",
        },
        detailedResponse: true
      }).catch(e => {
        if (e.error === "login_required" || e.error === "invalid_grant") {
          showNotification({ title: "Session Expired", message: "You will be redirected to the login page", color: "red" })
          loginWithRedirect({
            authorizationParams: {
              audience: environment.AUTH0_AUDIENCE,
              scope: "openid profile email",
            }
          });
        } else throw e;
      });
      if (newToken) {
        setAuth({ token: newToken.access_token, expireAt: DateTime.utc().plus({ seconds: newToken.expires_in - 5 }).toISO() })
      }
    }
    !auth.token && getToken()
  }, [auth])

  return (isLoading || isLoadingRoles) ? <LoadingOverlay visible /> : children;
};

export default ProtectedRoute;
