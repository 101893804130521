import { useAuth0 } from "@auth0/auth0-react";
import { AppShell, Center, Paper, Image, Loader, createStyles } from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import logo from "../assets/logo.png";
import { environment } from "../environments/environment";

const useStyles = createStyles((theme) => ({
  body: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
  }
}));

const Login = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { classes } = useStyles();

  useEffect(() => {
    const redirectURI = searchParams.get("redirectURI")
    if (redirectURI) {
      window.location.replace(redirectURI)
    } else {
      if (!isLoading) {
        if (isAuthenticated) {
          navigate("/");
        } else {
          loginWithRedirect({
            authorizationParams: {
              audience: environment.AUTH0_AUDIENCE,
              scope: "openid profile email",
            }
          });
        }
      }
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, navigate, searchParams]);

  return (
    <AppShell classNames={classes}>
      <Center sx={{ width: "100%", height: "100%" }}>
        <Paper shadow="md" p="40px" radius="md" sx={{ width: 350, maxWidth: "100%" }}>
          <Image src={logo} alt="Apis Innovation" width={200} mx="auto" />
          <Center mt="xl">
            <Loader mt="md" color="cyan.4" variant="dots" />
          </Center>
        </Paper>
      </Center>
    </AppShell>
  );
}

export default Login;
